.phone-input-container {
  font-family: inherit !important;
}

.phone-input-input {
  border: 0 !important;
  background: transparent !important;
  height: 38px !important;
  width: 100% !important;
  font-size: 1rem !important;
}

.phone-input-button {
  border: 0 !important;
  background: transparent !important;
}

.phone-input-button .selected-flag:hover {
  background: transparent !important;
}

.carousel .slider-wrapper {
  width: 100vw !important;
}

.carousel .slide {
  background: transparent !important;
}

.DataTableApartments table {
  overflow-x: clip !important;
}

.DataTableApartments thead tr th:has(.DataTableApartments__calendar) {
  padding-inline: 0;
}

.DataTableApartments tbody tr td:has(.DataTableApartments__calendar) {
  padding-inline: 0;
  width: 1px;
}

.DataTableApartments tbody tr td:first-child,
.DataTableApartments tbody tr td:nth-child(2) {
  z-index: 5 !important;
}
